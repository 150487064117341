import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    FormControl,
    TextField,
    Grid,
    Divider,
    Typography,
    Box,
    Button,
    Modal,
    FormGroup,
    FormControlLabel,
    Alert,
    Switch,
    Tooltip
} from '@mui/material';
import NotesIcon from '@mui/icons-material/Notes';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import PlaceIcon from '@mui/icons-material/PlaceOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { TagsInput } from 'react-tag-input-component';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/pt-br';
import { getScheduleSettings, updateSchedule } from 'services/schedule';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import FormHelperText from '@mui/material/FormHelperText';
import Lock from '@mui/icons-material/LockOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import AdditionalServices from './AdditionalServices';

const UpdateScheduleModal = ({ open, onClose, schedule, reloadEvents }) => {
    const clearedFormInputs = {
        location: {},
        title: '',
        start_date: new Date(),
        end_date: new Date(),
        description: '',
        guests: [],
        notify: true,
        private: false,
        external_guest_exists: false,
        guest_count: 0,
        services: [],
        online_meeting: false
    };

    const [inputs, setInputs] = useState(clearedFormInputs);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
    const [enabledServices, setEnabledServices] = useState(false);
    const [editableServices, setEditableServices] = useState(false);
    const [settings, setSettings] = useState({});

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const data = {
            location_id: inputs.location?.id,
            title: inputs.title,
            start: moment(inputs.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end: moment(inputs.end_date).format('YYYY-MM-DD HH:mm:ss'),
            description: inputs.description,
            guests: inputs.guests,
            notify: inputs.notify,
            private: inputs.private,
            external_guest_exists: inputs.external_guest_exists,
            guest_count: inputs.guest_count,
            services: inputs.services.map((service) => service.id),
            online_meeting: inputs.online_meeting
        };
        setErrors({});
        setAlert({ open: false, message: '', severity: 'success' });
        updateSchedule(inputs.id, data)
            .then((resp) => {
                setInputs(clearedFormInputs);
                onClose();
                enqueueSnackbar('Agendamento alterado com sucesso', { variant: 'success' });
                reloadEvents();
            })
            .catch((error) => {
                error.response.data?.errors && setErrors(error.response.data.errors);
                error.response.data?.error && setAlert({ open: true, message: error.response.data.error, severity: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleClose = () => {
        setInputs(clearedFormInputs);
        setErrors({});
        onClose();
    };

    const handleServices = () => {
        if (inputs.external_guest_exists) {
            setEnabledServices(true);
        } else {
            const start = moment(inputs.start_date);
            const end = moment(inputs.end_date);
            const duration = moment.duration(end.diff(start, 'minutes'));
            if (duration > 70) {
                setEnabledServices(true);
            } else {
                setEnabledServices(false);
                setInputs({ ...inputs, services: [] });
            }
        }
    };

    const handleEditableServices = () => {
        const start = moment(inputs.start_date);
        const now = moment();
        const diff = start.diff(now, 'minutes');
        if (diff > settings?.limit_request_service || inputs.external_guest_exists) setEditableServices(true);
        else setEditableServices(false);
    };

    useEffect(() => {
        if (open) {
            setErrors({});
            setAlert({ open: false, message: '', severity: 'success' });
            setInputs({
                ...inputs,
                id: schedule.id,
                title: schedule.title,
                start_date: moment(schedule.start).format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment(schedule.end).format('YYYY-MM-DD HH:mm:ss'),
                description: schedule.description || '',
                guests: schedule.guests?.map((guest) => guest.email),
                location: schedule.location,
                private: schedule.private ? true : false,
                external_guest_exists: schedule.external_guest_exists ? true : false,
                guest_count: schedule.guest_count,
                services: schedule.services,
                online_meeting: schedule.online_meeting ? true : false
            });
        }
    }, [open]);

    useEffect(() => {
        handleServices();
    }, [inputs.external_guest_exists, inputs.start_date, inputs.end_date]);

    useEffect(() => {
        handleEditableServices();
    }, [inputs.start_date, inputs.external_guest_exists]);

    useEffect(() => {
        getScheduleSettings().then(({ data }) => {
            setSettings(data.data);
        });
    }, []);

    return (
        <Modal open={open} onClose={handleClose} sx={{ height: '100vh', overflowY: 'scroll' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 0,
                    minWidth: 400,
                    maxWidth: 600,
                    maxHeight: '92vh',
                    overflowY: 'auto',
                    boxShadow: 24,
                    p: 4
                }}
            >
                <Grid container spacing={1} display="flex" direction="row" justifyContent="space-between" alignItems="center">
                    <Typography color="GrayText" variant="h3" component="h1" fontWeight={100}>
                        Editar agendamento
                    </Typography>
                    <Grid item display="flex" justifyContent="flex-end">
                        <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                {alert.message && (
                    <>
                        <Alert severity={alert.severity} variant="filled" sx={{ display: alert.open ? 'flex' : 'none' }}>
                            {alert.message}
                        </Alert>
                        <Divider sx={{ my: 1 }} />
                    </>
                )}
                <form onSubmit={handleSubmit}>
                    <Grid container display="flex" justifyContent="center" alignItems="end">
                        <Grid item xs={9} sm={9} md={10}>
                            <FormControl required fullWidth>
                                <TextField
                                    size="normal"
                                    inputProps={{ style: { fontSize: 16, color: '#333' } }}
                                    variant="filled"
                                    name="title"
                                    placeholder="Adicionar título"
                                    value={inputs.title}
                                    onChange={(e) => {
                                        setInputs({ ...inputs, title: e.target.value });
                                    }}
                                />
                                <FormHelperText error={true}>{errors.title}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} sm={3} md={2}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="warning"
                                            checked={inputs.private}
                                            onChange={(event) => setInputs({ ...inputs, private: event.target.checked })}
                                        />
                                    }
                                    label={
                                        <Tooltip title="Agendamento privado" arrow>
                                            <Lock fontSize="small" />
                                        </Tooltip>
                                    }
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={1} display="flex" alignItems="center">
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <PlaceIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl fullWidth>
                                <TextField
                                    type="text"
                                    label="Local"
                                    name="location"
                                    value={`${inputs.location?.description}`}
                                    placeholder="Local"
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={1} display="flex" alignItems="center">
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <AccessTimeIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl required fullWidth>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
                                    <DateTimePicker
                                        variant="standard"
                                        label="Data de Início"
                                        onChange={(value) => {
                                            setInputs({ ...inputs, start_date: value });
                                        }}
                                        type="date"
                                        name="start_date"
                                        id="start_date"
                                        ampm={false}
                                        value={inputs.start_date}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        inputFormat={'DD/MM/yyyy HH:mm'}
                                        disablePast
                                        minutesStep={5}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <AccessTimeIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl required fullWidth>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
                                    <DateTimePicker
                                        variant="standard"
                                        label="Data de Fim"
                                        onChange={(value) => {
                                            setInputs({ ...inputs, end_date: value });
                                        }}
                                        type="date"
                                        name="end_date"
                                        id="end_date"
                                        ampm={false}
                                        value={inputs.end_date}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        inputFormat={'DD/MM/yyyy HH:mm'}
                                        disablePast
                                        minutesStep={5}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="center">
                            <FormHelperText error={true}>{errors.start}</FormHelperText>
                            <FormHelperText error={true}>{errors.end}</FormHelperText>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={1} display="flex" alignItems="center">
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <NotesIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl required fullWidth>
                                <TextField
                                    type="text"
                                    multiline
                                    rows={3}
                                    name="description"
                                    value={inputs.description}
                                    placeholder="Adicionar descrição"
                                    label="Descrição"
                                    onChange={(e) => {
                                        setInputs({ ...inputs, description: e.target.value });
                                    }}
                                />
                                <FormHelperText error={true}>{errors.description}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={1} display="flex" alignItems="center">
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <EmailIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl required fullWidth>
                                <TagsInput
                                    name="guests"
                                    value={inputs.guests}
                                    placeHolder="Adicionar email dos convidados"
                                    onChange={(e) => {
                                        setInputs({ ...inputs, guests: e });
                                    }}
                                />
                                <FormHelperText error={true}>
                                    {Object.keys(errors).map((key) => String(key).indexOf('guests') > -1 && errors[key])}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={1} display="flex" alignItems="center">
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <AssignmentIndOutlinedIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={11}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={inputs.external_guest_exists}
                                            onChange={(event) => {
                                                setInputs({ ...inputs, external_guest_exists: event.target.checked });
                                            }}
                                        />
                                    }
                                    label="Na reunião, haverá participantes presenciais que não sejam colaboradores da nossa organização?"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={1} display="flex" alignItems="center">
                        <Grid item xs={12}>
                            <AdditionalServices
                                addededServices={inputs.services}
                                setAddedServices={(services) => {
                                    setInputs({ ...inputs, services: services });
                                }}
                                isDisabled={!enabledServices}
                                isEditable={editableServices}
                                guestCount={inputs.guest_count}
                                setGuestCount={(guestCount) => {
                                    setInputs({ ...inputs, guest_count: guestCount });
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={1} display="flex" alignItems="center">
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <GroupsOutlinedIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={11}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="success"
                                            checked={inputs.online_meeting}
                                            onChange={(event) => {
                                                setInputs({ ...inputs, online_meeting: event.target.checked });
                                            }}
                                        />
                                    }
                                    label="Está reunião também acontecerá de forma online?"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} display="flex" justifyContent="space-between">
                            <Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ mr: 1 }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={inputs.notify}
                                                onChange={(event) => setInputs({ ...inputs, notify: event.target.checked })}
                                            />
                                        }
                                        label="Notificar convidados"
                                    />
                                </FormGroup>
                            </Box>
                            <Button
                                variant="contained"
                                startIcon={!loading ? <EditCalendarIcon /> : <CircularProgress size={24} />}
                                type="submit"
                                size="large"
                                disabled={loading}
                            >
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};

export default UpdateScheduleModal;
