import { Box, Button, ButtonGroup, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState, createRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { locationCheck } from 'services/schedule';
import MainCard from 'ui-component/cards/MainCard';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const CheckIn = () => {
    const params = useParams();

    const [location, setLocation] = useState(null);
    const [unit, setUnit] = useState(null);

    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const handleCheckIn = () => {
        if (!location) return;
        locationCheck(location)
            .then((resp) => {
                setSuccess(true);
            })
            .catch((error) => {
                const message = error.response.data.message;
                setError(message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        handleCheckIn();
    }, [location]);

    useEffect(() => {
        if (params.unidade && params.local) {
            setUnit(params.unidade);
            setLocation(params.local);
        }
    }, []);

    return (
        <MainCard>
            <SnackbarProvider />
            <Box sx={{ py: '40px' }}>
                {loading && (
                    <Stack display="flex" justifyContent="center" alignItems="center" spacing={4}>
                        <Typography variant="h5">Aguarde, processando seu Check-In...</Typography>
                        <CircularProgress sx={{ mt: 2 }} />
                    </Stack>
                )}
                {success && (
                    <Stack display="flex" justifyContent="center" alignItems="center" spacing={4}>
                        <Typography variant="h5">Check-In realizado com sucesso!</Typography>
                        <CheckCircleOutlineOutlinedIcon color="success" sx={{ mt: 2, fontSize: 40 }} />
                    </Stack>
                )}
                {error && (
                    <Stack display="flex" justifyContent="center" alignItems="center" spacing={4}>
                        <Typography variant="h5">{error}</Typography>
                        <ErrorOutlineOutlinedIcon color="error" sx={{ my: 2, fontSize: 40 }} />
                    </Stack>
                )}
                {!loading && (
                    <Stack display="flex" justifyContent="center" alignItems="center" spacing={1} sx={{ mt: 4 }}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                setError(null);
                                setSuccess(false);
                                setLoading(true);
                                handleCheckIn();
                            }}
                        >
                            Tentar novamente
                        </Button>
                        <Button variant="outlined" color="info" onClick={() => navigate('/agendamentos')}>
                            Ir para agendamentos
                        </Button>
                    </Stack>
                )}
            </Box>
        </MainCard>
    );
};

export default CheckIn;
