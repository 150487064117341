import * as React from 'react';
import { useState, useEffect } from 'react';
import { Grid, FormControl, FormLabel, TextField, Button, Divider, Stack } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { updateScheduleSettings, getScheduleSettings } from 'services/schedule';
import { useNavigate } from 'react-router';

const Setting = () => {
    const navigate = useNavigate();

    const [checkToleranceBefore, setCheckToleranceBefore] = useState('');
    const [checkToleranceAfter, setCheckToleranceAfter] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [limitRequestService, setLimitRequestService] = useState('');

    const handleSave = () => {
        updateScheduleSettings([
            { key: 'check_tolerance_before', value: checkToleranceBefore },
            { key: 'check_tolerance_after', value: checkToleranceAfter },
            { key: 'admin_email', value: adminEmail },
            { key: 'limit_request_service', value: limitRequestService }
        ])
            .then(() => {
                enqueueSnackbar('Configurações salvas com sucesso', { variant: 'success' });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getScheduleSettings().then((response) => {
            const { data } = response.data;
            setCheckToleranceBefore(data.check_tolerance_before ?? '');
            setCheckToleranceAfter(data.check_tolerance_after ?? '');
            setAdminEmail(data.admin_email ?? '');
            setLimitRequestService(data.limit_request_service ?? '');
        });
    }, []);

    return (
        <MainCard title="Configurações" icon={<SettingsOutlinedIcon color="primary" />}>
            <SnackbarProvider />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormLabel component="legend" sx={{ pb: 2 }}>
                        Tolerância para o Check In
                    </FormLabel>
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormControl fullWidth>
                        <TextField
                            label="minutos antes do início"
                            type="number"
                            value={checkToleranceBefore}
                            onChange={(e) => setCheckToleranceBefore(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormControl fullWidth>
                        <TextField
                            label="minutos após o início"
                            type="number"
                            value={checkToleranceAfter}
                            onChange={(e) => setCheckToleranceAfter(e.target.value)}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <FormLabel component="legend" sx={{ pb: 2 }}>
                            Limite para solicitar serviço no agendamento
                        </FormLabel>
                        <TextField
                            label="minutos antes do início da reunião"
                            value={limitRequestService}
                            onChange={(e) => setLimitRequestService(e.target.value)}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <FormLabel component="legend" sx={{ pb: 2 }}>
                            E-mail administrativo
                        </FormLabel>
                        <TextField label="E-mail" value={adminEmail} onChange={(e) => setAdminEmail(e.target.value)} />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container justifyContent="start" sx={{ mt: 3 }} spacing={2}>
                <Grid item xs={6}>
                    <Stack spacing={2} direction="row">
                        <Button variant="text" onClick={() => navigate(-1)}>
                            Voltar
                        </Button>
                        <AnimateButton>
                            <Button type="button" variant="contained" onClick={() => handleSave()}>
                                Salvar
                            </Button>
                        </AnimateButton>
                    </Stack>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default Setting;
