import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import LocationTimeline from 'views/pages/Timeline/LocationTimeline';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const PublicRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/timeline',
            element: <LocationTimeline />
        }
    ]
};

export default PublicRoutes;
