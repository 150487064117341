import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Typography,
    Box,
    Modal,
    Card,
    CardHeader,
    CardContent,
    IconButton,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Stack,
    Chip,
    Divider,
    FormHelperText,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Link,
    Tooltip,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    CardActions
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PeopleIcon from '@mui/icons-material/People';
import moment from 'moment';
import 'moment/locale/pt-br';
import { deleteSchedule, checkIn, getScheduleSettings } from 'services/schedule';
import UpdateScheduleModal from './UpdateScheduleModal';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { CircularProgress } from '@mui/material';

const ViewScheduleModal = ({ open, onClose, scheduleSelected, reloadEvents }) => {
    const [schedule, setSchedule] = useState({});

    const [editSchedule, setEditSchedule] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [settings, setSettings] = useState({});

    const [loading, setLoading] = useState(false);

    const authUser = useSelector((state) => state.auth.user);

    const isOwnerSchedule = (sched) => {
        return sched.user_id === authUser.id;
    };

    const canEditSchedule = (sched) => {
        return isOwnerSchedule(sched) && moment().isBefore(sched.end);
    };

    const handleClose = () => {
        onClose();
    };

    const handleDelete = () => {
        setLoading(true);
        deleteSchedule(schedule.id).then((resp) => {
            setConfirmDelete(false);
            setSchedule({});
            handleClose();
            reloadEvents();
            setLoading(false);
            enqueueSnackbar('Agendamento excluído com sucesso!', { variant: 'success' });
        });
    };

    const handleEdit = () => {
        setEditSchedule(true);
    };

    const checkInCompleted = () => {
        return schedule.checks?.find((check) => check.user_id === authUser.id);
    };

    useEffect(() => {
        if (open) setSchedule(scheduleSelected);
    }, [open]);

    useEffect(() => {
        getScheduleSettings().then(({ data }) => {
            setSettings(data.data);
        });
    }, []);

    return (
        <Modal open={open} onClose={handleClose} sx={{ height: '100vh', overflowY: 'scroll' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 0,
                    minWidth: 400,
                    maxWidth: 600,
                    maxHeight: '92vh',
                    overflowY: 'auto',
                    boxShadow: 24,
                    padding: 0
                }}
            >
                <SnackbarProvider />
                <Card>
                    <CardHeader
                        action={
                            <>
                                <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ ml: 2 }}>
                                    {canEditSchedule(schedule) && (
                                        <>
                                            <IconButton onClick={handleEdit}>
                                                <EditCalendarIcon />
                                            </IconButton>
                                            <IconButton onClick={() => setConfirmDelete(true)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </>
                                    )}
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </>
                        }
                        title={schedule?.title}
                        subheader={moment(schedule?.start).locale('pt-br').format('LL')}
                    />
                    <CardMedia component="img" height="240" image="/img/room.jpg" />
                    <CardContent sx={{ p: 2 }}>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">Início:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">
                                                {moment(schedule?.start).locale('pt-br').format('HH:mm')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6">Fim:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{moment(schedule?.end).locale('pt-br').format('HH:mm')}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">Local:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{schedule?.location?.description}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6">Check-In:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {checkInCompleted() ? (
                                                <Chip color="success" label="Realizado" />
                                            ) : (
                                                <Chip color="warning" label="Pendente" />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">Descrição:</Typography>
                                        </TableCell>
                                        <TableCell colSpan={4}>
                                            <Typography variant="body1">{schedule?.description}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">Anfitrião:</Typography>
                                        </TableCell>
                                        <TableCell colSpan={4}>
                                            <Tooltip title={schedule.owner?.email} arrow>
                                                <Chip color="primary" label={schedule.owner?.name} />
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                    {schedule?.guests?.length > 0 && (
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="h6">Convidados:</Typography>
                                            </TableCell>
                                            <TableCell colSpan={4}>
                                                <Stack direction="row" spacing={1}>
                                                    {schedule?.guests?.map((guest) => (
                                                        <Chip color="success" key={guest.email} label={guest.email} sx={{ mr: 1 }} />
                                                    ))}
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {schedule?.external_meeting_url && schedule?.online_meeting ? (
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="h6">Link da Reunião:</Typography>
                                            </TableCell>
                                            <TableCell colSpan={4}>
                                                <FormControl fullWidth>
                                                    <OutlinedInput
                                                        fullWidth
                                                        type="text"
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => {
                                                                        navigator.clipboard.writeText(schedule?.external_meeting_url);
                                                                        enqueueSnackbar('Link copiado!', { variant: 'success' });
                                                                    }}
                                                                    edge="end"
                                                                >
                                                                    <ContentCopyIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        value={schedule?.external_meeting_url}
                                                        readOnly
                                                    />
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    ) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                    <CardActions sx={{ p: 3 }}>
                        <Stack direction="row" spacing={1} justifyContent="end">
                            {schedule?.external_meeting_url && schedule?.online_meeting ? (
                                <Link href={schedule?.external_meeting_url} target="_blank" rel="noreferrer">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ bgcolor: '#4f52b2', ':hover': { bgcolor: '#7b83eb' } }}
                                        startIcon={<PeopleIcon />}
                                    >
                                        Ir para reunião
                                    </Button>
                                </Link>
                            ) : null}
                        </Stack>
                    </CardActions>
                </Card>
                <Dialog
                    open={confirmDelete}
                    onClose={() => setConfirmDelete(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{'Atenção!'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Você tem certeza que deseja excluir esse agendamento?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {loading && (
                            <Box display="flex" justifyContent="flex-start" alignItems="center">
                                <CircularProgress size={20} sx={{ mr: 2 }} />
                                <Typography variant="legend" component="p" color="gray">
                                    Processando...
                                </Typography>
                            </Box>
                        )}
                        <Button onClick={() => setConfirmDelete(false)}>Não</Button>
                        <Button onClick={handleDelete}>Sim</Button>
                    </DialogActions>
                </Dialog>
                <UpdateScheduleModal
                    open={editSchedule}
                    onClose={() => setEditSchedule(false)}
                    schedule={schedule}
                    reloadEvents={() => {
                        reloadEvents();
                        handleClose();
                    }}
                />
            </Box>
        </Modal>
    );
};

export default ViewScheduleModal;
