import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    FormControl,
    TextField,
    Grid,
    Divider,
    Typography,
    Box,
    Button,
    Modal,
    InputLabel,
    Select,
    MenuItem,
    Table,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    Chip,
    Fab,
    Tooltip,
    Checkbox,
    ListItemText,
    OutlinedInput,
    Alert
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import DevicesIcon from '@mui/icons-material/Devices';
import SearchIcon from '@mui/icons-material/Search';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/pt-br';
import { getLocations } from 'services/location';
import { getTags } from 'services/tag';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const FindLocationSchedule = ({ open, onClose, unit, units, handleFindAddLocation }) => {
    const clearedFormInputs = {
        unit: {},
        start_date: null,
        end_date: null,
        capacity: '',
        tags: []
    };

    const [inputs, setInputs] = useState(clearedFormInputs);
    const [tagsOption, setTagsOption] = useState([]);
    const [results, setResults] = useState([]);

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });

    const handleSearch = (e) => {
        e.preventDefault();
        if (!inputs.start_date || !inputs.end_date) {
            setAlert({ open: true, message: 'Informe a data de início e fim', severity: 'warning' });
            setTimeout(() => {
                setAlert({ open: false, message: '', severity: 'success' });
            }, 3000);
            return;
        }
        setResults([]);
        setLoading(true);
        getLocations(
            '',
            '',
            inputs.tags.map((x) => x.id),
            inputs.capacity,
            moment(inputs.start_date).format('YYYY-MM-DD HH:mm:ss'),
            moment(inputs.end_date).format('YYYY-MM-DD HH:mm:ss'),
            inputs.unit?.id
        ).then((resp) => {
            const data = resp.data.data;
            if (data.length === 0) {
                setAlert({ open: true, message: 'Nenhuma sala encontrada', severity: 'warning' });
                setTimeout(() => {
                    setAlert({ open: false, message: '', severity: 'success' });
                }, 3000);
            }
            setResults(data);
            setLoading(false);
        });
    };

    const handleFind = (location) => {
        onClose();
        handleFindAddLocation(location, moment(inputs.start_date).format(), moment(inputs.end_date).format());
    };

    useEffect(() => {
        if (open) {
            setInputs({ ...clearedFormInputs, unit: unit });
            setResults([]);
            getTags().then((resp) => setTagsOption(resp.data.data));
        }
    }, [open]);

    return (
        <Modal open={open} onClose={onClose} sx={{ height: '100vh', overflowY: 'scroll' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 0,
                    minWidth: 400,
                    maxWidth: 600,
                    maxHeight: '92vh',
                    overflowY: 'auto',
                    boxShadow: 24,
                    p: 4
                }}
            >
                <Grid container direction="row" spacing={1} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography color="GrayText" variant="h3" component="h3" fontWeight={100}>
                        Procurar salas disponíveis
                    </Typography>
                    <Grid item display="flex" justifyContent="flex-end">
                        <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <form onSubmit={handleSearch}>
                    <Grid container spacing={2} display="flex" alignItems="center">
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="unit-label">Unidade</InputLabel>
                                <Select
                                    labelId="unit-label"
                                    label="Unidade"
                                    onChange={(e) => {
                                        setInputs({ ...inputs, unit: e.target.value });
                                    }}
                                    renderValue={(value) => value.description}
                                    value={inputs.unit}
                                >
                                    {units?.map((unit) => (
                                        <MenuItem key={unit.id} value={unit}>
                                            {unit.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ my: 1 }} />
                        </Grid>
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <AccessTimeIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={5}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
                                <DateTimePicker
                                    variant="standard"
                                    label="Data de Início"
                                    onChange={(e) => {
                                        setInputs({ ...inputs, start_date: e });
                                    }}
                                    type="date"
                                    name="start_date"
                                    id="start_date"
                                    ampm={false}
                                    value={inputs.start_date}
                                    disablePast
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    inputFormat={'DD/MM/yyyy HH:mm'}
                                    minutesStep={5}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <AccessTimeIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={5}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
                                <DateTimePicker
                                    variant="standard"
                                    label="Data de Fim"
                                    onChange={(e) => {
                                        setInputs({ ...inputs, end_date: e });
                                    }}
                                    type="date"
                                    name="end_date"
                                    id="end_date"
                                    ampm={false}
                                    value={inputs.end_date}
                                    disablePast
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    inputFormat={'DD/MM/yyyy HH:mm'}
                                    minutesStep={5}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <GroupIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                label="Capacidade"
                                id="capacity"
                                type="number"
                                name="capacity"
                                value={inputs.capacity}
                                onChange={(e) => {
                                    setInputs({ ...inputs, capacity: e.target.value });
                                }}
                            />
                        </Grid>
                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                            <LocalOfferOutlinedIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="tags-label">Tags</InputLabel>
                                <Select
                                    labelId="tags-label"
                                    id="multiple-chip"
                                    multiple
                                    value={inputs.tags}
                                    onChange={(e) => setInputs({ ...inputs, tags: e.target.value })}
                                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value.id}
                                                    label={value.description}
                                                    clickable
                                                    deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                                                    sx={{ display: 'flex', flexWrap: 'wrap' }}
                                                    onDelete={(e) => {
                                                        setInputs({
                                                            ...inputs,
                                                            tags: inputs.tags.filter((tag) => tag.id !== value.id)
                                                        });
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {tagsOption.map((option) => (
                                        <MenuItem key={option.id} value={option}>
                                            <ListItemText primary={option.description} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    {results.length > 0 && (
                        <Grid container spacing={2} display="flex" alignItems="center">
                            <Grid item xs={1} display="flex" justifyContent="flex-end">
                                <ManageSearchIcon fontSize="small" />
                            </Grid>
                            <Grid item xs={11}>
                                <TableContainer sx={{ border: 1, borderColor: 'grey.400', overflowY: 'scroll', height: '240px' }}>
                                    <Table>
                                        <TableBody>
                                            {results?.map((location) => (
                                                <TableRow key={location.id}>
                                                    <TableCell>
                                                        <Typography variant="h4" component="h2">
                                                            {location.description}
                                                        </Typography>
                                                        <Typography variant="p" component="p">
                                                            Capacidade: {location.capacity}
                                                        </Typography>
                                                        <Typography variant="p" component="p">
                                                            {location.tags?.map((tag) => (
                                                                <Chip label={tag.description} sx={{ mr: 1 }} />
                                                            ))}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip title="Reservar sala">
                                                            <Fab variant="contained" size="small">
                                                                <EditCalendarIcon onClick={() => handleFind(location)} />
                                                            </Fab>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    )}
                    <Alert severity={alert.severity} variant="filled" sx={{ display: alert.open ? 'flex' : 'none' }}>
                        {alert.message}
                    </Alert>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} display="flex" justifyContent="space-between">
                            <Box display="flex" justifyContent="flex-start" alignItems="center">
                                {loading && (
                                    <>
                                        <CircularProgress size={24} sx={{ mr: 2 }} />
                                        <Typography variant="legend" component="p" color="gray">
                                            Processando...
                                        </Typography>
                                    </>
                                )}
                            </Box>
                            <Button
                                variant="contained"
                                startIcon={<SearchIcon />}
                                type="submit"
                                size="large"
                                onClick={handleSearch}
                                disabled={loading}
                            >
                                Pesquisar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};

export default FindLocationSchedule;
