import PropTypes from 'prop-types';
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router';
import Calendar from 'views/pages/Calendar/Calendar';
import Profile from 'views/pages/Profile/Profile';
import Tag from 'views/pages/Tag/Tag';
import GridUsers from 'views/pages/User/GridUser';
import Setting from 'views/pages/Setting/Setting';
import CheckIn from 'views/pages/Calendar/CheckIn';

const GridLocation = Loadable(lazy(() => import('views/pages/Location/GridLocation')));
const Location = Loadable(lazy(() => import('views/pages/Location/Location')));

// ==============================|| MAIN ROUTING ||============================== //

const AuthRouter = ({ children }) => {
    let token = localStorage.getItem('token');
    return token ? children : <Navigate to={`/login?redirect=${window.location.href}`} />;
};
AuthRouter.propTypes = {
    children: PropTypes.object
};
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/agendamentos',
            element: (
                <AuthRouter>
                    <Calendar />
                </AuthRouter>
            )
        },
        {
            path: '/unidade/:unidade/local/:local',
            element: (
                <AuthRouter>
                    <CheckIn />
                </AuthRouter>
            )
        },
        {
            path: '/locais',
            element: (
                <AuthRouter>
                    <GridLocation />
                </AuthRouter>
            )
        },
        {
            path: '/novo_local',
            element: (
                <AuthRouter>
                    <Location />
                </AuthRouter>
            )
        },
        {
            path: '/local/:id/:action',
            element: (
                <AuthRouter>
                    <Location />
                </AuthRouter>
            )
        },
        {
            path: '/perfil',
            element: (
                <AuthRouter>
                    <Profile />
                </AuthRouter>
            )
        },
        {
            path: '/tags',
            element: (
                <AuthRouter>
                    <Tag />
                </AuthRouter>
            )
        },
        {
            path: '/usuarios',
            element: (
                <AuthRouter>
                    <GridUsers />
                </AuthRouter>
            )
        },
        {
            path: '/configuracoes',
            element: (
                <AuthRouter>
                    <Setting />
                </AuthRouter>
            )
        }
    ]
};

export default MainRoutes;
