import * as React from 'react';
import { useState } from 'react';
import {
    Grid,
    Divider,
    Typography,
    Box,
    Button,
    Modal,
    Alert,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Stack,
    Tooltip,
    Fab,
    Link
} from '@mui/material';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import CloseIcon from '@mui/icons-material/Close';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import { Scanner } from '@yudiel/react-qr-scanner';
import { grey } from '@mui/material/colors';

import moment from 'moment';
import { getEvents } from 'services/user-events';

const ReadQRCodeModal = ({ open, onClose, user, handleGoToSchedule, handleLocation }) => {
    const [isScanning, setIsScanning] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
    const [results, setResults] = useState([]);
    const [unit, setUnit] = useState(null);
    const [location, setLocation] = useState(null);

    const startScanning = () => {
        setResults([]);
        setIsScanning(true);
    };

    const handleClose = () => {
        setIsScanning(false);
        setAlert({ open: false, message: '', severity: 'success' });
        onClose();
    };

    const handleSchedule = (schedule) => {
        handleGoToSchedule(schedule);
        handleClose();
    };

    const handleResult = (result) => {
        const { REACT_APP_CHECKIN_MIN_TOLERANCE } = process.env;
        const scheduleTime = moment().add(REACT_APP_CHECKIN_MIN_TOLERANCE, 'm').format('YYYY-MM-DD HH:mm:ss');

        const splitAction = result[0]?.rawValue?.split('/');

        const unitId = splitAction[splitAction.length - 3];
        const locationId = splitAction[splitAction.length - 1];

        setUnit(unitId);
        setLocation(locationId);

        getEvents(scheduleTime, locationId)
            .then((response) => {
                const { data } = response;
                const results = data.filter((schedule) => schedule.checked_at == null);
                if (results.length > 0) {
                    setResults(results);
                    setIsScanning(false);
                } else {
                    setAlert({ open: true, message: 'Nenhum agendamento pendente encontrado', severity: 'error' });
                    setIsScanning(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsScanning(false);
            });
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: 0,
                    minWidth: 400,
                    maxWidth: 600,
                    boxShadow: 24,
                    p: 4
                }}
            >
                <Grid container direction="row" spacing={1} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography color="GrayText" variant="h2" component="h1" fontWeight={100}>
                        Verificar agendamento
                    </Typography>
                    <Grid item display="flex" justifyContent="flex-end">
                        <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                {isScanning && (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        mt={2}
                        display="flex"
                        justifyContent="flex-end"
                        flexDirection="column"
                        gap="5px"
                    >
                        <Scanner onScan={(result) => handleResult(result)} />
                        <Button startIcon={<CloseIcon />} onClick={() => setIsScanning(false)} variant="outlined">
                            Cancelar
                        </Button>
                    </Grid>
                )}
                {!isScanning && (
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            style={{ width: '240px', height: '240px' }}
                        >
                            {(results.length > 0 && (
                                <TableContainer sx={{ border: 1, borderColor: 'grey.400', overflowY: 'scroll', height: '240px' }}>
                                    <Table>
                                        <TableBody>
                                            {results?.map((schedule) => (
                                                <TableRow key={schedule.id}>
                                                    <TableCell>
                                                        <Typography variant="h4" component="h2">
                                                            {schedule.title}
                                                        </Typography>
                                                        <Typography variant="legend" component="p">
                                                            {moment(schedule?.start).locale('pt-br').format('LLL')}
                                                        </Typography>
                                                        <Typography variant="p" component="p">
                                                            {schedule.description}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip title="Ir para reunião">
                                                            <Fab variant="contained" size="small" color="success">
                                                                <MeetingRoomIcon onClick={() => handleSchedule(schedule)} />
                                                            </Fab>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )) || <QrCodeScannerIcon style={{ fontSize: '160px', color: grey[300] }} />}
                        </Grid>
                        <Grid item xs={12}>
                            <Alert severity={alert.severity} variant="filled" fullWidth sx={{ display: alert.open ? 'flex' : 'none' }}>
                                {alert.message}
                            </Alert>
                        </Grid>

                        <Grid item xs={12}>
                            <Stack display="flex" justifyContent="center" direction="column" spacing={2}>
                                {unit && location && results.length > 0 ? (
                                    <Link href={'/unidade/' + unit + '/local/' + location} underline="hover">
                                        <Button
                                            variant="contained"
                                            startIcon={<CheckCircleOutlineOutlinedIcon />}
                                            fullWidth
                                            color="success"
                                        >
                                            Fazer Check-In
                                        </Button>
                                    </Link>
                                ) : null}
                                <Button variant="contained" startIcon={<QrCode2Icon />} onClick={startScanning} fullWidth>
                                    Ler QR Code
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Modal>
    );
};

export default ReadQRCodeModal;
