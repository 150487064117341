import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { app } from '../../../../services/Api';

const MicrosoftCallback = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);

    const redirectLogin = () => {
        const redirect = params.get('state')?.split('=')[1];
        window.location.href = redirect != 'null' ? redirect : '/agendamentos';
    };

    useEffect(() => {
        const fetchToken = async () => {
            const code = params.get('code');
            if (code) {
                const response = await app.get(`microsoft/callback?code=${code}`);
                localStorage.setItem('token', response.data.token);
                dispatch({ type: 'SET_LOGIN', payload: response.data.user });
                redirectLogin();
            }
        };

        fetchToken();
    }, [navigate]);

    return <div>Loading...</div>;
};

export default MicrosoftCallback;
